<template>
  <Render />
</template>

<script lang="ts" setup>
  const props = defineProps<{
    tag: string,
    editable: boolean,
  }>();

  const emit = defineEmits();

  const model = defineModel({ type: String });

  // Custom rendering is needed due to ssr / innerText and dynamic tag handling
  const Render = () => h(props.tag, {
    class: 'focus:outline-none',
    contenteditable: props.editable ? 'plaintext-only' : 'false',
    ref: 'editableContent',
    onInput,
    onPaste,
    onKeydown: (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    },
  }, model.value);

  const onInput = (event: Event) => {
    const target = event.target as HTMLElement;

    model.value = target.innerText;
  };

  const onPaste = (event: ClipboardEvent) => {
    const text = event.clipboardData?.getData('text/plain') || '';

    document.execCommand('insertText', false, text);
  };
</script>
