<template>
  <div
    class="border border-slate-200 border-t-4 rounded-lg shadow shadow-slate-300/20"
    :class="{
      'border-t-amber-400': selectedColor === 'amber',
      'border-t-teal-400': selectedColor === 'teal',
      'border-t-sky-400': selectedColor === 'sky',
      'border-t-violet-400': selectedColor === 'violet',
      'border-t-pink-400': selectedColor === 'pink',
      'border-t-rose-400': selectedColor === 'rose',
    }"
    @mouseover="hovering = true"
    @mouseleave="hovering = false"
  >
    <div class="p-5">
      <h2 class="flex items-center justify-between gap-3 font-semibold mb-2 text-lg text-slate-800">
        <div class="flex items-center gap-3">
          <div
            class=" bg-gradient-to-tr flex items-center justify-center h-5 w-5 rounded-full"
            :class="{
              'to-amber-300 from-amber-500': selectedColor === 'amber',
              'to-teal-300 from-teal-500': selectedColor === 'teal',
              'to-sky-300 from-sky-500': selectedColor === 'sky',
              'to-violet-300 from-violet-500': selectedColor === 'violet',
              'to-pink-300 from-pink-500': selectedColor === 'pink',
              'to-rose-300 from-rose-500': selectedColor === 'rose',
            }"
          >
            <IconsStar />
          </div>

          <PortollRichText
            v-model="plan.name"
            tag="span"
            :editable="isOwner"
          />
        </div>

        <Popover
          v-if="isOwner"
          class="relative"
        >
          <PopoverButton class="h-6 w-6 focus:outline-none">
            <Icon
              name="material-symbols:palette"
              class="h-6 w-6 text-slate-400"
            />
          </PopoverButton>

          <PopoverPanel class="bg-white flex flex-row gap-2 mr-2 px-4 py-3 rounded shadow-lg absolute top-8 -right-7 z-10">
            <button
              v-for="color in colorOptions"
              class="h-8 w-8 rounded"
              :class="{
                'bg-amber-400 focus:outline-none focus:ring-2 focus:ring-amber-600': color === 'amber',
                'bg-teal-400 focus:outline-none focus:ring-2 focus:ring-teal-600': color === 'teal',
                'bg-sky-400 focus:outline-none focus:ring-2 focus:ring-sky-600': color === 'sky',
                'bg-violet-400 focus:outline-none focus:ring-2 focus:ring-violet-600': color === 'violet',
                'bg-pink-400 focus:outline-none focus:ring-2 focus:ring-pink-600': color === 'pink',
                'bg-rose-400 focus:outline-none focus:ring-2 focus:ring-rose-600': color === 'rose',
              }"
              @click="selectedColor = color"
            />
          </PopoverPanel>
        </Popover>
      </h2>

      <PortollRichText
        v-model="plan.description"
        tag="p"
        class="text-sm text-slate-600"
        :editable="isOwner"
      />

      <div class="font-bold mt-3 mb-4 text-slate-800">
        <span class="text-lg">$</span><PortollRichText
          v-model="monthlyAmount"
          tag="span"
          class="text-3xl"
          :editable="isOwner"
        /><span class="font-thin text-sm text-slate-700">/mo</span>
      </div>

      <PortollButton
        kind="primary"
        class="w-full"
        @click="emit('get-started')"
      >
        Get Started
      </PortollButton>
    </div>

    <div class="border-t border-slate-200 p-5">
      <div class="font-semibold text-slate-600 text-xs uppercase">
        What's Included
      </div>

      <div
        v-if="plan.features.length"
        class="py-3 space-y-1 text-sm"
      >
        <div
          v-for="(feature, index) in plan.features"
          :key="`feature-${index}`"
          ref="featureRefs"
          class="flex gap-2"
        >
          <Icon
            name="material-symbols:check-small-rounded"
            class="relative bottom-1 h-7 w-7 text-green-500"
          />

          <PortollRichText
            v-model="feature.text"
            tag="div"
            class="text-slate-600 focus:outline-none"
            :editable="isOwner"
            @keydown.native.enter="onAddNewFeature"
          />
        </div>
      </div>

      <button
        v-if="isOwner"
        class="flex gap-2 items-center mt-2 ml-2 text-sm text-indigo-600 transition-colors duration-150 ease-in-out hover:text-indigo-800 focus:outline-none focus:text-indigo-800"
        @click="onAddNewFeature"
      >
        <Icon
          name="material-symbols:add-rounded"
          class="h-4 w-4"
        />

        Add feature
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { useAuthStore } from '~/stores/useAuthStore';
  import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';

  const { isOwner } = useAuthStore();

  const props = defineProps<{
    plan: SubscriptionPlan;
  }>();

  const emit = defineEmits(['get-started', 'add-feature']);

  const hovering = ref<boolean>(false);
  const monthlyAmount = ref<string>('');
  const selectedColor = ref<string>('teal');
  const featureRefs = ref<Array<HTMLElement | null>>([]);
  const colorOptions = ['amber', 'teal', 'sky', 'violet', 'pink', 'rose'];

  monthlyAmount.value = formatMoney(props.plan.monthly_amount);
  selectedColor.value = props.plan.color;

  watchEffect(() => {
    props.plan.monthly_amount = monthlyAmount.value * 100;
  });

  watchEffect(() => {
    props.plan.color = selectedColor.value;
  });

  function onAddNewFeature() {
    emit('add-feature');

    setTimeout(() => {
      moveCaretToEnd(
        featureRefs.value[featureRefs.value.length - 1].children[1] as HTMLElement
      );
    }, 0);
  }

  function moveCaretToEnd(element: HTMLElement) {
    element.focus();

    const range = document.createRange();
    range.selectNodeContents(element);
    range.collapse(false);

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
}
</script>
