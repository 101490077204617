<template>
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 10C4.53976 10 4.16667 9.6269 4.16667 9.16667C4.16667 7.32572 2.67428 5.83333 0.833333 5.83333C0.535612 5.83333 0.260506 5.6745 0.111645 5.41667C-0.0372152 5.15883 -0.0372152 4.84117 0.111645 4.58333C0.260506 4.3255 0.535612 4.16667 0.833333 4.16667C2.67428 4.16667 4.16667 2.67428 4.16667 0.833333C4.16667 0.373096 4.53976 0 5 0C5.46024 0 5.83333 0.373096 5.83333 0.833333C5.83333 2.67428 7.32572 4.16667 9.16667 4.16667C9.6269 4.16667 10 4.53976 10 5C10 5.46024 9.6269 5.83333 9.16667 5.83333C7.32572 5.83333 5.83333 7.32572 5.83333 9.16667C5.83333 9.6269 5.46024 10 5 10Z"
      fill="white"
    />
  </svg>
</template>
